<template>
  <div>
    <div class="row">
      <div class="col">
        <div v-if="showWizard">
          <h3 class="text-center mb-4">Intresseanmälan till Code Summer Camp 2024</h3>

          <p>Kul att du är intresserad av att delta på Code Summer Camp i sommar!</p>

          <p>Lägret genomförs på dagtid. Frukostmacka, lunch och mellis ingår.</p>

          <p>
            Lägervecka 25 (17-20 juni) för dig mellan 10-17 år, digitalt läger på distans
            <br />
            Lägervecka 25 (17-20 juni) För dig mellan 7-9 år, fysiskt läger, barnet
            behöver ha fyllt 7 år senast dagen då lägret startar<br />
            Lägervecka 26 (24-28 juni) för dig mellan 10-12 år, fysiskt läger<br />
            Lägervecka 27(1 juli-5 juli) för dig mellan 13-17 år, fysiskt läger
          </p>

          <h5>Viktig information - läs igenom noga</h5>

          <p>
            För att göra en intresseanmälan besvarar du formuläret nedan. Inom kort kommer
            det ett mejl med en länk. Klicka på länken för att bekräfta intresseanmälan.
            Var noga med att den mejladress som du har uppgett är korrekt.
          </p>
          <p>
            När ansökningstiden har passerat kommer sker urval genom lottning. Den 2 maj
            får du ett mejl med besked om ert barn har fått antingen deltagarplats eller
            reservplats. Du kan tacka nej till erbjudande om plats om du ångrar dig.
          </p>

          <form-wizard v-model="wizardStep" :before-completed="beforeCompleted">
            <participant-info-step
              :experience.sync="enrollment.participant.experience"
              :literacy.sync="enrollment.participant.literacy"
              :first-name.sync="enrollment.participant.firstName"
              :last-name.sync="enrollment.participant.lastName"
              :birthday.sync="enrollment.participant.birthday"
              :personalNumber.sync="enrollment.participant.personalNumber"
              :street-address.sync="enrollment.participant.streetAddress"
              :zipcode.sync="enrollment.participant.zipcode"
              :city.sync="enrollment.participant.city"
            />
            <select-week-step
              :birthday="enrollment.participant.birthday"
              :personalNumber="enrollment.participant.personalNumber"
              :week.sync="enrollment.camp.week"
              :ticket-type.sync="enrollment.camp.ticketType"
            />
            <parent-info-step
              :second-parent-email.sync="enrollment.parentInfo.secondParentEmail"
              :second-parent-name.sync="enrollment.parentInfo.secondParentName"
              :second-parent-phone.sync="enrollment.parentInfo.secondParentPhone"
              :parent-name.sync="enrollment.parentInfo.parentName"
              :parent-phone.sync="enrollment.parentInfo.parentPhone"
              :email.sync="enrollment.parentInfo.email"
            />
          </form-wizard>
        </div>
        <div v-else>
          <h1>Tack för din intresseanmälan!</h1>
          <p>
            Du får inom kort ett epostmeddelande med en länk för att
            <strong>bekräfta din anmälan</strong>. Den 2:e maj kommer du att få meddelande
            om du fått plats på årets läger eller inte.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import gql from "graphql-tag";
import FormWizard from "@/components/FormWizard/FormWizard/FormWizard.vue";
import ParticipantInfoStep from "@/components/enrollment/ParticipantInfoStep.vue";
import SelectWeekStep from "@/components/enrollment/SelectWeekStep.vue";
import ParentInfoStep from "@/components/enrollment/ParentInfoStep.vue";

@Component({
  components: {
    FormWizard,
    ParticipantInfoStep,
    SelectWeekStep,
    ParentInfoStep,
  },
})
export default class EnrollmentPage extends Vue {
  wizardStep = 1;
  showWizard = true;

  enrollment = {
    participant: {
      firstName: "",
      lastName: "",
      birthday: "",
      personalNumber: "",
      streetAddress: "",
      zipcode: "",
      city: "",
      literacy: false,
      experience: null,
    },
    camp: {
      week: null,
      ticketType: null,
    },
    parentInfo: {
      parentName: "",
      parentPhone: "",
      email: "",
      secondParentName: "",
      secondParentPhone: "",
      secondParentEmail: "",
    },
  };
  beforeCompleted() {
    // Send request etc.
    // Return false (sync or async) to prevent completion
    this.showWizard = false;
    this.$apollo
      .mutate({
        // Query
        mutation: gql`
          mutation(
            $firstName: String!
            $lastName: String!
            $personalNumber: String!
            $streetAddress: String!
            $zipcode: String!
            $city: String!
            $weekId: ID!
            $ticketTypeId: ID!
            $parentName: String!
            $parentPhone: String!
            $email: String!
            $secondParentName: String!
            $secondParentPhone: String!
            $secondParentEmail: String!
            $literacy: Boolean!
            $experience: String!
          ) {
            enrollment(
              input: {
                firstName: $firstName
                lastName: $lastName
                personalNumber: $personalNumber
                streetAddress: $streetAddress
                zipcode: $zipcode
                city: $city
                parentName: $parentName
                parentPhone: $parentPhone
                email: $email
                secondParentName: $secondParentName
                secondParentPhone: $secondParentPhone
                secondParentEmail: $secondParentEmail
                weekId: $weekId
                ticketTypeId: $ticketTypeId
                literacy: $literacy
                experience: $experience
              }
            ) {
              id
              firstName
              lastName
            }
          }
        `,
        // Parameters
        variables: {
          firstName: this.enrollment.participant.firstName,
          lastName: this.enrollment.participant.lastName,
          personalNumber: this.enrollment.participant.personalNumber,
          streetAddress: this.enrollment.participant.streetAddress,
          zipcode: this.enrollment.participant.zipcode,
          city: this.enrollment.participant.city,
          ticketTypeId: this.enrollment.camp.ticketType,
          weekId: this.enrollment.camp.week,
          parentName: this.enrollment.parentInfo.parentName,
          parentPhone: this.enrollment.parentInfo.parentPhone,
          email: this.enrollment.parentInfo.email,
          secondParentName: this.enrollment.parentInfo.secondParentName,
          secondParentPhone: this.enrollment.parentInfo.secondParentPhone,
          secondParentEmail: this.enrollment.parentInfo.secondParentEmail,
          literacy: this.enrollment.participant.literacy,
          experience: this.enrollment.participant.experience,
        },
      })
      .then((data) => {
        this.enrollment = {
          participant: {
            firstName: "",
            lastName: "",
            birthday: "",
            personalNumber: "",
            streetAddress: "",
            zipcode: "",
            city: "",
            literacy: false,
            experience: null,
          },
          camp: {
            week: null,
            ticketType: null,
          },
          parentInfo: {
            parentName: "",
            parentPhone: "",
            email: "",
            secondParentName: "",
            secondParentPhone: "",
            secondParentEmail: "",
          },
        };
      })
      .catch((error) => {
        // Error
        console.error(error);
        // We restore the initial user input
        // this.newTag = newTag;
      });

    return true;
  }
}
</script>

<style></style>
